<template>
<b-container fluid class="analysis-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3 min-height-fit">
    <b-card no-body class="m-0 p-3 flex-grow-1 d-flex flex-column min-height-fit">
      <live-iv-graph />
    </b-card>
  </div>
</b-container>
</template>

<script>
import { BContainer, BCard } from 'bootstrap-vue';

import LiveIvGraph from '../components/graphs/LiveIvGraph.vue';

export default {
  name: 'LiveIVGraph',
  components: {
    LiveIvGraph,
    BContainer,
    BCard
  }
};
</script>

<style scoped>

.analysis-container {
  height: calc(100vh - 105px)
}

.analysis-container .tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.analysis-container .tab-pane.active {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.min-height-fit {
  min-height: fit-content;
}
</style>
