<template>
  <div class="flex-grow-1 d-flex flex-column px-1 px-md-2 pt-2 pb-2">
    <b-row class="m-0 mb-2" no-gutters>
      <b-col cols="12" md="8" lg="8" xl="5" class="px-2">
        <better-search-multiselect
          v-model="selectedSolarModules"
          :options="solarModuleOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Module"
          track-by="uuid"
          label="name"
          group-label="group"
          group-values="modules"
          selectGroupLabel=""
          deselectGroupLabel=""
          group-select
          multiple
          :closeOnSelect="false"
          :limit="2"
          :disabled="requestInProgress">
            <template slot="option" slot-scope="{props}">
              <div class="option__desc">
                <span v-if="props.option.$groupLabel" class="option__title">
                  {{ props.option.$groupLabel }}
                </span>
                <span class="option__title">
                  {{ props.option.name }}
                  <font-awesome-icon
                    v-if="props.option.linkedDaq"
                    v-b-tooltip="`Module linked to ${props.option.linkedDaq}`"
                    icon="link" class="ml-2" />
                </span>
              </div>
            </template>
        </better-search-multiselect>
      </b-col>
      <b-col cols="12" md="auto" class="pl-2 mt-2 mt-md-0 d-flex justify-content-center align-items-center">
        <b-button
        aria-label="latest IV"
        variant="primary"
        class="mr-2"
        @click="() => handleGetLatestIV()"
        :disabled="requestInProgress || !this.selectedSolarModules.length"
        block
        v-if="!requestInProgress"
        v-b-tooltip.hover
        title="Obtain the latest IV">
          Get Latest IV
        </b-button>
        <msi-spinner class="ml-2 no-select" :size="30" v-if="requestInProgress" />
      </b-col>
    </b-row>
    <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
      <high-charts-responsive class="highcharts-container" :options="ivCurveGraph" ref="ivGraph"/>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          aria-label="clear IVs"
          variant="primary"
          class="mx-1"
          @click="handleClearIVs"
          size="sm"
          :disabled="!ivCurveGraph.series.length">
          Clear IVs
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue';
import { get } from 'vuex-pathify';

import BetterSearchMultiselect from '../BetterSearchMultiselect.vue';
// eslint-disable-next-line import/no-named-default
import { default as HighChartsResponsive, ivButtons } from '../HighChartsResponsive.vue';
import MsiSpinner from '../MsiSpinner.vue';
import { extractIVCurve, getIvTooltip } from './helpers';

export default {
  name: 'LiveIVGraph',
  components: {
    HighChartsResponsive,
    MsiSpinner,
    BetterSearchMultiselect,
    BRow,
    BCol,
    BButton
  },
  data() {
    const component = this;
    const resetZoomStopPropagation = e => e.stopPropagation();
    return {
      selectedSolarModules: [],
      requestInProgress: false,
      ivCurveGraph: {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          panning: true,
          panKey: 'shift',
          events: {
            redraw: () => {
              const { chart } = component.$refs.ivGraph;
              if (chart && chart.resetZoomButton) {
                chart.resetZoomButton.element.addEventListener('click', resetZoomStopPropagation);
              }
            }
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          title: {
            text: 'Voltage'
          },
          labels: {
            format: '{value}V'
          },
          showEmpty: false
        },
        yAxis: {
          title: {
            text: 'Current'
          },
          labels: {
            format: '{value}A'
          },
          showEmpty: false
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            animation: false,
            lineWidth: 1,
            events: {
              hide() {
                const opPointSeries = this.chart.series.find(s => s.name === 'Operating Points');
                if (!opPointSeries) return;

                const opPoint = opPointSeries.data.find(({ id }) => id === `${this.name} - Op Point`);
                if (opPoint && opPoint.marker) opPoint.marker.enabled = false;
              },
              show() {
                const opPointSeries = this.chart.series.find(s => s.name === 'Operating Points');
                if (!opPointSeries) return;

                const opPoint = opPointSeries.data.find(({ id }) => id === `${this.name} - Op Point`);
                if (opPoint && opPoint.marker) opPoint.marker.enabled = true;
              }
            }
          }
        },
        series: [],
        tooltip: {
          formatter: function formatter() {
            let { metaData, name: seriesName } = this.series.options;
            if (this.point.options.linkedSeries) {
              ({ metaData, name: seriesName } = this.point.options.linkedSeries);
            }
            return getIvTooltip(this.series, this.point, metaData, seriesName);
          },
          useHTML: true,
        },
        legend: {
          padding: 20,
          maxHeight: 90
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              },
              yAxis: {
                title: {
                  text: ''
                }
              }
            }
          }]
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ivButtons
            }
          },
          filename: 'live-iv'
        }
      }
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    getSolarModuleByUuid: get('solarmodules/getSolarModuleByUuid'),
    getSiteGroupedModules: get('groups/getSiteGroupedModules'),
    solarModuleOptions() {
      if (!this.selectedSite) return [];
      return this.getSiteGroupedModules(this.selectedSite.id).map(({ group, modules }) => ({ group: group.name, modules }));
    }
  },
  methods: {
    handleClearIVs() {
      this.$set(this.ivCurveGraph, 'series', []);
    },
    async getIVCurves(moduleIds) {
      const siteCode = this.selectedSite.id;
      const requests = moduleIds.map(moduleUuid => this.$daqApi.getLatestIVCurve(siteCode, { query: { moduleUuid } }));
      const responses = await Promise.all(requests.map((request, index) => request
        .then(value => ({ status: 'fulfilled', value }))
        .catch((e) => {
          if (e.name === 'ApiError') {
            const solarModule = this.getSolarModuleByUuid(moduleIds[index]);
            if (solarModule) this.$toastError(`Error ${e.status || ''}`, `Module ${solarModule.name}: ${e.message}.`);
            return { status: 'rejected' };
          }

          throw e;
        })));

      const validModules = [];
      const validResponses = [];
      responses.forEach((response, index) => {
        if (response.status === 'fulfilled') {
          validModules.push(moduleIds[index]);
          validResponses.push(response.value);
        }
      });

      if (validResponses.length === 0) return [];
      return validResponses.map((response, index) => {
        const moduleId = validModules[index];
        const solarModule = this.getSolarModuleByUuid(moduleId);
        const extractedIVCurve = extractIVCurve([response]);
        const { ivCurve, metaData } = extractedIVCurve;
        return { name: `Module ${this.getFullModuleName(solarModule)}`, data: ivCurve, metaData, moduleId };
      });
    },
    async handleGetLatestIV(moduleIds) {
      if (!this.selectedSolarModules.length && !moduleIds) return;
      this.requestInProgress = true;

      try {
        if (!moduleIds) moduleIds = this.selectedSolarModules.map(sModule => sModule.uuid);
        const ivCurves = await this.getIVCurves(moduleIds);
        const series = this.addPmpAndPopToSeries(ivCurves);
        this.$set(this.ivCurveGraph, 'series', []);
        // Next tick to avoid bug when re-plotting series with same id.
        this.$nextTick(() => this.$set(this.ivCurveGraph, 'series', series));
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    addPmpAndPopToSeries(series) {
      const pops = [];
      const newSeries = series.map((s) => {
        const { data, metaData } = s;
        const marker = { fillColor: s.color, lineColor: 'black', lineWidth: 3, radius: 6, enabled: true, symbol: 'circle' };
        const { vop, iop, vmp, imp } = metaData;
        if (vop && iop) {
          pops.push({
            displayName: 'Operating Point',
            marker: { ...marker, symbol: 'diamond' },
            linkedSeries: s,
            id: `${s.name} - Op Point`,
            x: +vop,
            y: +iop,
          });
        }
        const newData = data.map(([x, y]) => {
          if (x.toFixed(2) === (+vmp).toFixed(2) && y.toFixed(2) === (+imp).toFixed(2)) {
            return { marker, displayName: 'Max. Power Point', x, y };
          }
          return { x, y };
        });
        return { ...s, data: newData };
      });
      newSeries.push({ name: 'Operating Points', data: pops, type: 'scatter', showInLegend: false, lineWidth: 0 });
      return newSeries;
    },
    plotFromQuery() {
      if (!this.selectedSite) return;
      const { modules } = this.$route.query;
      const options = this.getSiteSolarModules(this.selectedSite.id);
      if (!modules || !options.length) return;

      if (Array.isArray(modules)) {
        modules.forEach((moduleUuid) => {
          const found = options.find(option => option.uuid === moduleUuid);
          const inSelected = this.selectedSolarModules.find(option => option.uuid === moduleUuid);
          if (found && !inSelected) this.selectedSolarModules.push(found);
        });
      } else {
        const found = options.find(option => option.uuid === modules);
        const inSelected = this.selectedSolarModules.find(option => option.uuid === modules);
        if (found && !inSelected) this.selectedSolarModules.push(found);
      }
      this.handleGetLatestIV();
      this.$router.replace({ query: null });

    }
  },
  activated() {
    this.plotFromQuery();
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler() {
        this.selectedSolarModules = [];
        this.handleClearIVs();
      }
    },
    solarModuleOptions: {
      immediate: true,
      handler(options) {
        if (options.length === 0) return;
        this.plotFromQuery();
      }
    }
  }
};
</script>
